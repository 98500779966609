import { useReducer, createContext, useContext, useMemo, useEffect } from 'react';

// Helpers
import * as api from 'utils/api';
import getPagination from 'utils/getPagination';
import throwResponseError from 'utils/throwResponseError';
import { useGetFavoriteFacesPhotos } from 'hooks/Storefront/useGetFavoriteFacesPhotos';

// Types
import { Photo, PhotosFilter, Pagination, Tag, FaceFind, ServerError, ErrorObject, Notification } from 'types';

interface PhotosState {
  photos: Photo[] | null;
  photosAccessId: string | null;
  favorites: Photo[] | null;
  favoritesAccessId: string | null;
  favoritesGalleryId: string | null;

  filter: PhotosFilter | null;
  filterTags: string | null;
  filterFace: FaceFind | null;
  filterSimilar: string | null;

  pagination: Pagination | null;

  requesting: { photos: boolean; favorite: boolean };
  notification: Notification | null;
}

interface GetPhotos {
  galleryId: string;

  accessId?: string;
  filter: PhotosFilter;
  tags?: string;

  pagination?: {
    page: number;
    perPage: number;
  };
  infiniteScrolling?: boolean;
}

interface GetFavoritePhotos {
  galleryId: string;
  accessId?: string;
}

interface GetFaceFindPhotos {
  galleryId: string;

  faceFind?: FaceFind;

  pagination?: {
    page: number;
    perPage: number;
  };
  infiniteScrolling?: boolean;
}

interface GetRelatedPhotos {
  photoId?: string;

  pagination?: {
    page: number;
    perPage: number;
  };
  infiniteScrolling?: boolean;
}

interface CreateFavoritePhoto {
  galleryId: string;
  photoId: string;

  action: 'favorite' | 'unfavorite';
}

interface CreateBulkFavoritePhotos {
  galleryId: string;
  photoIds: string[];

  action: 'favorite' | 'unfavorite';
}

interface PhotosProviderProps {
  photosState: PhotosState;

  getPhotos: (payload: GetPhotos) => Promise<any>;
  getFavoritePhotos: (payload: GetFavoritePhotos) => Promise<any>;
  getRelatedPhotos: (payload: GetRelatedPhotos) => Promise<any>;
  getFaceFindPhotos: (payload: GetFaceFindPhotos) => Promise<any>;

  createFavoritePhoto: (payload: CreateFavoritePhoto) => Promise<any>;
  createBulkFavoritePhotos: (payload: CreateBulkFavoritePhotos) => Promise<any>;

  resetPhotosState: () => void;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';

const SET_PHOTOS = 'SET_PHOTOS';
const SET_FAVORITES_PHOTOS = 'SET_FAVORITES_PHOTOS';

const SET_FILTER = 'SET_FILTER';
const SET_FILTER_TAGS = 'SET_FILTER_TAGS';
const SET_FILTER_FACE = 'SET_FILTER_FACE';
const SET_FILTER_SIMILAR = 'SET_FILTER_SIMILAR';

const SET_PAGINATION = 'SET_PAGINATION';

const RESET_STATE = 'RESET_STATE';

const initialState = {
  photos: null,
  photosAccessId: null,
  favorites: null,
  favoritesAccessId: null,
  favoritesGalleryId: null,

  filter: null,
  filterTags: null,
  filterFace: null,
  filterSimilar: null,

  pagination: null,

  requesting: { photos: false, favorite: false },
  notification: null
};

export const PhotosContext = createContext<PhotosProviderProps>({
  photosState: initialState,

  getPhotos: () => Promise.resolve(),
  getFavoritePhotos: () => Promise.resolve(),
  getRelatedPhotos: () => Promise.resolve(),
  getFaceFindPhotos: () => Promise.resolve(),

  createFavoritePhoto: () => Promise.resolve(),
  createBulkFavoritePhotos: () => Promise.resolve(),

  resetPhotosState: () => null
});

const reducer = (state: PhotosState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_PHOTOS:
      return {
        ...state,
        photos: payload.photos,
        photosAccessId: payload.photosAccessId
      };

    case SET_FAVORITES_PHOTOS:
      return {
        ...state,
        favorites: payload.favorites,
        favoritesAccessId: payload.favoritesAccessId,
        favoritesGalleryId: payload.favoritesGalleryId
      };

    case SET_FILTER:
      return {
        ...state,
        filter: payload.filter
      };

    case SET_FILTER_TAGS:
      return {
        ...state,
        filterTags: payload.filterTags
      };

    case SET_FILTER_FACE:
      return {
        ...state,
        filterFace: payload.filterFace
      };

    case SET_FILTER_SIMILAR:
      return {
        ...state,
        filterSimilar: payload.filterSimilar
      };

    case SET_PAGINATION:
      return {
        ...state,
        pagination: payload.pagination
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

export const PhotosProvider = (props: { children: React.ReactNode }) => {
  const [photosState, dispatch] = useReducer(reducer, initialState);

  const getPhotos = async (payload: GetPhotos) => {
    const { galleryId, filter, tags: payloadFilterTags, pagination: payloadPagination, infiniteScrolling = false, accessId } = payload;
    const { photos: statePhotos, filterTags: stateFilterTags, pagination: statePagination } = photosState;

    const tags: Tag[] = payloadFilterTags ? payloadFilterTags : stateFilterTags;
    const pagination: Pagination = payloadPagination
      ? payloadPagination
      : statePagination
        ? { ...statePagination, page: getPagination(statePagination.page, statePagination.perPage, statePagination.totalPhotos) }
        : { page: 1, perPage: 100 };

    try {
      dispatch({ type: SET_REQUESTING, payload: { photos: infiniteScrolling ? false : true } });

      const response = await api.get({
        resource: `jobs/${galleryId}/photos`,
        urlParams: {
          ...(filter === 'tags' ? { tags } : ''),
          ...(filter === 'favorites' ? { favorite: true } : ''),
          ...(accessId ? { access_id: accessId } : {}),
          page: pagination.page,
          per_page: pagination.perPage
        }
      });

      return await response
        .json()
        .then((data: Photo[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const photos: Photo[] = infiniteScrolling ? [...statePhotos, ...data] : data;
          const totalPhotos: number = Number(response.headers.get('x-total')) || 0;

          dispatch({ type: SET_PHOTOS, payload: { photos, photosAccessId: accessId || null } });
          dispatch({ type: SET_FILTER, payload: { filter } });
          dispatch({ type: SET_FILTER_TAGS, payload: { filterTags: tags } });
          dispatch({ type: SET_PAGINATION, payload: { pagination: { ...pagination, totalPhotos } } });

          return { photos: data, filter };
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { photos: false } });
    }
  };

  const getFavoritePhotos = async (payload: GetFavoritePhotos) => {
    const { galleryId, accessId } = payload;

    try {
      const response = await api.get({
        resource: `jobs/${galleryId}/photos`,
        urlParams: { favorite: true, ...(accessId ? { access_id: accessId } : {}) }
      });

      return await response
        .json()
        .then((data: Photo[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({ type: SET_FAVORITES_PHOTOS, payload: { favorites: data, favoritesAccessId: accessId || null, favoritesGalleryId: galleryId } });

          return { favorites: data };
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    }
  };

  const getFaceFindPhotos = async (payload: GetFaceFindPhotos) => {
    const { galleryId, faceFind: payloadFilterFace, pagination: payloadPagination, infiniteScrolling = false } = payload;
    const { photos: statePhotos, photosAccessId, filterFace: stateFilterFace, pagination: statePagination } = photosState;

    const imageAttachment: FaceFind = payloadFilterFace ? payloadFilterFace : stateFilterFace;
    const { faceFile, faceFileImgUri, faceFileExifOrientation } = imageAttachment;
    const pagination: Pagination = payloadPagination
      ? payloadPagination
      : { ...statePagination, page: getPagination(statePagination.page, statePagination.perPage, statePagination.totalPhotos) };

    try {
      dispatch({ type: SET_REQUESTING, payload: { photos: infiniteScrolling ? false : true } });

      const response = await api.post({
        resource: `jobs/${galleryId}/photos/search`,
        urlParams: {
          page: pagination.page,
          per_page: pagination.perPage
        },
        bodyPayload: {
          image_attachment: {
            filename: faceFile?.name,
            content_type: faceFile?.type,
            content: faceFileImgUri,
            orientation: faceFileExifOrientation
          }
        }
      });

      return await response
        .json()
        .then((data: Photo[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const photos: Photo[] = infiniteScrolling ? [...statePhotos, ...data] : data;
          const totalPhotos: number = Number(response.headers.get('x-total')) || 0;

          dispatch({ type: SET_PHOTOS, payload: { photos, photosAccessId } });
          dispatch({ type: SET_FILTER, payload: { filter: 'faceFind' } });
          dispatch({ type: SET_FILTER_FACE, payload: { filterFace: imageAttachment } });
          dispatch({ type: SET_PAGINATION, payload: { pagination: { ...pagination, totalPhotos } } });

          return { photos: data, filter: 'faceFind', filterFace: imageAttachment };
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { photos: false } });
    }
  };

  const getRelatedPhotos = async (payload: GetRelatedPhotos) => {
    const { photoId: payloadFilterSimilar, pagination: payloadPagination, infiniteScrolling = false } = payload;
    const { photos: statePhotos, filterSimilar: stateFilterSimilar, pagination: statePagination } = photosState;

    const photoId: string = payloadFilterSimilar ? payloadFilterSimilar : stateFilterSimilar;
    const pagination: Pagination = payloadPagination
      ? payloadPagination
      : { ...statePagination, page: getPagination(statePagination.page, statePagination.perPage, statePagination.totalPhotos) };

    try {
      dispatch({ type: SET_REQUESTING, payload: { photos: infiniteScrolling ? false : true } });

      const response = await api.get({
        resource: `photos/${photoId}/related`,
        urlParams: {
          page: pagination.page,
          per_page: pagination.perPage
        }
      });

      return await response
        .json()
        .then((data: Photo[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const photos: Photo[] = infiniteScrolling ? [...statePhotos, ...data] : data;
          const totalPhotos: number = Number(response.headers.get('x-total')) || 0;

          dispatch({ type: SET_PHOTOS, payload: { photos, photosAccessId: null } });
          dispatch({ type: SET_FILTER, payload: { filter: 'similar' } });
          dispatch({ type: SET_FILTER_SIMILAR, payload: { filterSimilar: photoId } });
          dispatch({ type: SET_PAGINATION, payload: { pagination: { ...pagination, totalPhotos } } });

          return { photos: data, filter: 'similar', filterSimilar: photoId };
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { photos: false } });
    }
  };

  const createFavoritePhoto = async (payload: { galleryId: string; photoId: string; action: 'favorite' | 'unfavorite' }) => {
    const { galleryId, photoId, action } = payload;
    const { photos, photosAccessId } = photosState;

    let updatedPhotos: Photo[] = [...(photos !== null ? photos : [])];

    try {
      const response = await api.post({
        resource: `jobs/${galleryId}/photos/${action}`,
        bodyPayload: { photo_id: photoId }
      });

      if (response.ok) {
        // Find favorite/unfavorite photo index and update `is_favorite` property in place
        const foundIndex = updatedPhotos.findIndex((photo: Photo) => photo.id === photoId);

        if (foundIndex > -1) {
          // Create new object so we don't mutate global reference to this photo
          const updatedPhoto = { ...updatedPhotos[foundIndex], is_favorited: action === 'favorite' ? true : false };

          updatedPhotos = [...updatedPhotos.slice(0, foundIndex), updatedPhoto, ...updatedPhotos.slice(foundIndex + 1)];
        }

        dispatch({ type: SET_PHOTOS, payload: { photos: updatedPhotos, photosAccessId } });

        return updatedPhotos;
      } else {
        return await response
          .json()
          .then((data: any & ServerError) => {
            // Catch error
            if (data.error) {
              const errorObject = { code: response.status, message: data.error_localized || data.error };

              throw errorObject;
            }
          })
          .catch((error: ErrorObject) => throwResponseError(response, error));
      }
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    }
  };

  const createBulkFavoritePhotos = async (payload: { galleryId: string; photoIds: string[]; action: 'favorite' | 'unfavorite' }) => {
    const { galleryId, photoIds, action } = payload;
    const { photos, photosAccessId } = photosState;
    const updatedPhotos: Photo[] = [...(photos !== null ? photos : [])];

    try {
      const response = await api.post({
        resource: `jobs/${galleryId}/photos/${action}`,
        bodyPayload: { photo_ids: photoIds }
      });

      if (response.ok) {
        if (action === 'unfavorite') {
          updatedPhotos.forEach((photo) => (photo.is_favorited = false));

          dispatch({ type: SET_PHOTOS, payload: { photos: updatedPhotos, photosAccessId } });

          return updatedPhotos;
        } else {
          updatedPhotos.forEach((photo) => (photo.is_favorited = true));

          dispatch({ type: SET_PHOTOS, payload: { photos: updatedPhotos, photosAccessId } });

          return updatedPhotos;
        }
      } else {
        return await response
          .json()
          .then((data: any & ServerError) => {
            // Catch error
            if (data.error) {
              const errorObject = { code: response.status, message: data.error_localized || data.error };

              throw errorObject;
            }
          })
          .catch((error: ErrorObject) => throwResponseError(response, error));
      }
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    }
  };

  const resetPhotosState = () => dispatch({ type: RESET_STATE });
  const { invalidateFavoriteFacesPhotosQuery } = useGetFavoriteFacesPhotos({ storefrontGalleryId: photosState.favoritesGalleryId });

  useEffect(() => {
    invalidateFavoriteFacesPhotosQuery();
  }, [photosState.favorites]);

  const providerValue = useMemo(
    () => ({
      photosState,

      getPhotos,
      getFavoritePhotos,
      getRelatedPhotos,
      getFaceFindPhotos,

      createFavoritePhoto,
      createBulkFavoritePhotos,

      resetPhotosState
    }),
    [photosState]
  );

  return <PhotosContext.Provider value={providerValue}>{props.children}</PhotosContext.Provider>;
};

export const usePhotosContext = () => {
  const context = useContext(PhotosContext);

  if (context === undefined) {
    throw new Error('usePhotosContext must be used within an PhotosProvider ');
  }

  return context;
};
