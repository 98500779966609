import { useReducer, createContext, useContext, useMemo, useEffect } from 'react';

// Helpers
import * as api from 'utils/api';
import throwResponseError from 'utils/throwResponseError';
import { useGetFavoriteFacesPhotos } from 'hooks/Storefront/useGetFavoriteFacesPhotos';

// Types
import {
  Product,
  Package,
  Preview,
  ProductCategory,
  ServerError,
  ErrorObject,
  Notification,
  OrderItem,
  LayoutTheme,
  OrderItemProduct,
  Cart,
  RetouchPhoto,
  CustomerAddress,
  OrderItemProductNode
} from 'types';

interface StorefrontState {
  currentGalleryId: string | null;
  cart: Cart | null;
  product: Product | null;
  products: Product[] | null;
  featuredProducts: Product[] | null;
  popularProducts: Product[] | null;

  package: Package | null;
  packages: Package[] | null;

  themes: LayoutTheme[] | null;
  previews: Preview[] | null;

  category: ProductCategory | null;
  categories: ProductCategory[] | null;

  activeItem: OrderItem | null; // Current Order Item Product may be Package or Product
  activeItemProduct: OrderItemProduct | null; // Current Order Item Product
  activeProductNodesSnapshot: ActiveItemProductNodesSnapshot | null;
  activePhotoId: string | null; // User selected photo

  retouchPhotos: { [cartId: string]: RetouchPhoto[] } | null;

  requesting: {
    cart: boolean;
    updateCart: boolean;
    themes: boolean;
    preview: boolean;
    products: boolean;
    orderItem: boolean;
    orderItemProduct: boolean;
    retouchPhotos: boolean;
    suspiciousReport: boolean;
  };
  notification: Notification | null;
}

interface StorefrontProviderProps {
  storefrontState: StorefrontState;

  getCart: (payload: { totals?: boolean }) => Promise<Cart | null>;
  updateCart: (payload: UpdateCart) => Promise<any>;

  getThemes: (payload: { productId: string }) => Promise<any>;
  getPreview: (payload: { layoutThemeId: string }) => Promise<any>;
  getProducts: (payload: { pricesheetId: string }) => Promise<any>;
  getPopularProducts: (payload: { pricesheetId: string }) => Promise<any>;

  setCart: (payload: { cart: Cart }) => void;
  setActiveItem: (payload: { orderItem: OrderItem | null; product?: OrderItemProduct }) => void;
  setActiveProductNodesSnapshot: (payload: { productSnapshot: ActiveItemProductNodesSnapshot | null }) => void;
  setActivePhotoId: (payload: { photoId: string }) => void;

  createOrderItem: (payload: CreateOrderItem) => Promise<any>;
  updateOrderItem: (payload: UpdateOrderItem) => Promise<any>;
  deleteOrderItem: (payload: DeleteOrderItem) => Promise<any>;

  updateOrderItemProduct: (payload: UpdateOrderItemProduct) => Promise<any>;
  deleteOrderItemProduct: (payload: DeleteOrderItemProduct) => Promise<any>;

  getRetouchPhotos: (payload: { cartId: string }) => Promise<any>;
  updateRetouchPhotos: (payload: UpdateRetouchPhotos) => Promise<any>;

  createSuspiciousReport: (payload: CreateSuspiciousReport) => Promise<any>;

  resetCart: () => void;
  resetStorefrontState: () => void;
}

interface UpdateCart {
  cartId: string;
  shippingRateId?: string;
  addressAttributes?: CustomerAddress;
  email?: string;
  phone?: string;
  smsEnabled?: boolean;
}

interface CreateOrderItem {
  jobId: string;
  itemId: string;
  quantity: number;
  photoId?: string;
  subjectAccessId?: string;
  backgroundId?: string;
  digitalBundleSize?: number;
  complete?: boolean;
}

interface UpdateOrderItem {
  orderItemId: string;
  complete?: boolean;
  quantity?: number;
  digitalBundleSize?: number;
  disableAutoClear?: boolean;
}

interface DeleteOrderItem {
  orderItemId: string;
}

interface DeleteOrderItemProduct {
  orderItemId: string;
  orderItemProductId: string;
}

interface UpdateOrderItemProduct {
  orderItemProductId: string;
  quantity?: number;
  photoId?: string;
  layoutThemeId?: string;
  backgroundId?: string;
  previewConfirmed?: boolean;
  includePreview?: boolean;
  orderItemProductNodeAttributes?: { id: string; text_node_key?: string; value?: string; photo_id?: string; background_id?: string }[];
}

interface UpdateRetouchPhotos {
  cartId: string;
  photos: RetouchPhoto[];
}

interface ActiveItemProductNodesSnapshot {
  id: string;
  incompleteNodes: any;
  unconfirmedChanges: any;
  imageNodes: OrderItemProductNode[] | null;
  textNodes: OrderItemProductNode[] | null;
  activeNode: OrderItemProductNode | null;
}

interface CreateSuspiciousReport {
  [key: string]: string;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';
const SET_CART = 'SET_CART';
const SET_THEMES = 'SET_THEMES';
const SET_PREVIEWS = 'SET_PREVIEWS';
const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_POPULAR_PRODUCTS = 'SET_POPULAR_PRODUCTS';
const SET_ACTIVE_ITEM = 'SET_ACTIVE_ITEM';
const SET_ACTIVE_PRODUCT_NODES_SNAPSHOT = 'SET_ACTIVE_PRODUCT_NODES_SNAPSHOT';
const SET_ACTIVE_PHOTO_ID = 'SET_ACTIVE_PHOTO_ID';
const SET_RETOUCH_PHOTOS = 'SET_RETOUCH_PHOTOS';

const RESET_CART = 'RESET_CART';
const RESET_STATE = 'RESET_STATE';

const initialState = {
  currentGalleryId: null,
  cart: null,

  product: null,
  products: null,
  featuredProducts: null,
  popularProducts: null,

  package: null,
  packages: null,

  themes: null,
  previews: null,

  category: null,
  categories: null,

  activeItem: null,
  activeItemProduct: null,
  activeProductNodesSnapshot: null,
  activePhotoId: null,

  retouchPhotos: null,

  requesting: {
    cart: false,
    updateCart: false,
    themes: false,
    preview: false,
    products: false,
    orderItem: false,
    orderItemProduct: false,
    retouchPhotos: false,
    suspiciousReport: false
  },
  notification: null
};

export const StorefrontContext = createContext<StorefrontProviderProps>({
  storefrontState: initialState,

  getCart: async () => null,
  updateCart: () => Promise.resolve(),
  getThemes: () => Promise.resolve(),
  getPreview: () => Promise.resolve(),
  getProducts: () => Promise.resolve(),
  getPopularProducts: () => Promise.resolve(),

  setCart: () => null,
  setActiveItem: () => null,
  setActiveProductNodesSnapshot: () => null,
  setActivePhotoId: () => null,

  createOrderItem: () => Promise.resolve(),
  updateOrderItem: () => Promise.resolve(),
  deleteOrderItem: () => Promise.resolve(),

  updateOrderItemProduct: () => Promise.resolve(),
  deleteOrderItemProduct: () => Promise.resolve(),

  getRetouchPhotos: () => Promise.resolve(),
  updateRetouchPhotos: () => Promise.resolve(),

  createSuspiciousReport: () => Promise.resolve(),

  resetCart: () => null,
  resetStorefrontState: () => null
});

const reducer = (state: StorefrontState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_CART:
      return {
        ...state,
        cart: payload.cart,
        notification: payload.notification
      };

    case SET_THEMES:
      return {
        ...state,
        themes: payload.themes,
        notification: payload.notification
      };

    case SET_PREVIEWS:
      return {
        ...state,
        previews: state?.previews ? [...state?.previews, payload.preview] : [payload.preview],
        notification: payload.notification
      };

    case SET_PRODUCTS:
      return {
        ...state,
        currentGalleryId: payload.pricesheetId,
        products: payload.products,
        featuredProducts: payload.featuredProducts,
        popularProducts: payload.popularProducts,
        packages: payload.packages,
        categories: payload.categories,
        notification: payload.notification
      };

    case SET_POPULAR_PRODUCTS:
      return {
        ...state,
        popularProducts: payload.popularProducts
      };

    case SET_ACTIVE_ITEM:
      return {
        ...state,
        activeItem: payload.activeItem,
        activeItemProduct: payload.activeItemProduct,
        notification: payload.notification
      };

    case SET_ACTIVE_PRODUCT_NODES_SNAPSHOT:
      return {
        ...state,
        activeProductNodesSnapshot: payload.activeProductNodesSnapshot
      };

    case SET_ACTIVE_PHOTO_ID:
      return {
        ...state,
        activePhotoId: payload.photoId
      };

    case SET_RETOUCH_PHOTOS:
      return {
        ...state,
        retouchPhotos: payload.retouchPhotos,
        notification: payload.notification
      };

    case RESET_CART:
      return {
        ...state,
        cart: null
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used as HOC Wrapper around Routes
export const StorefrontProvider = (props: { children: React.ReactNode }) => {
  const [storefrontState, dispatch] = useReducer(reducer, initialState);

  // Actions
  const getCart = async (payload: { totals?: boolean }) => {
    const { totals } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { cart: true } });

      const urlParams = {
        ...(totals ? { totals: true } : { include_preview: true })
      };

      const response = await api.get({ resource: `cart`, urlParams });
      if (response.ok) {
        return await response
          .json()
          .then((data: Cart[] & ServerError) => {
            if (data.error) {
              const errorObject = { code: response.status, message: data.error_localized || data.error };

              throw errorObject;
            }

            const { cart: currentCart } = storefrontState;
            const responseCart = data.length > 0 ? data[0] : null;

            let updatedCart = responseCart;

            // If slim call only update total properties
            if (totals === true) {
              updatedCart = { ...currentCart, ...responseCart };
            }

            dispatch({
              type: SET_CART,
              payload: {
                cart: updatedCart
              }
            });

            if (!updatedCart) {
              dispatch({
                type: SET_ACTIVE_ITEM,
                payload: {
                  activeItem: null,
                  activeItemProduct: null
                }
              });
            }

            return updatedCart;
          })
          .catch((error: ErrorObject) => throwResponseError(response, error));
      } else {
        const errorObject = { code: response.status, message: await response.text() };

        throw errorObject;
      }
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { cart: false } });
    }
  };

  const updateCart = async (payload: UpdateCart) => {
    const { cartId, shippingRateId, addressAttributes, email, phone, smsEnabled } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { updateCart: true } });

      const bodyPayload = {
        ...(shippingRateId ? { shipping_rate_id: shippingRateId } : {}),
        ...(addressAttributes ? { address_attributes: addressAttributes } : {}),
        ...(email ? { email } : {}),
        ...(phone ? { phone } : {}),
        ...(typeof smsEnabled === 'boolean' ? { sms_enabled: smsEnabled } : {})
      };

      const response = await api.put({ resource: `orders/${cartId}`, bodyPayload });

      return await response
        .json()
        .then((data: Cart & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_CART,
            payload: {
              cart: data
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { updateCart: false } });
    }
  };

  const getPopularProducts = async (payload: { pricesheetId: string }) => {
    const { pricesheetId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { products: true } });

      const response = await api.get({ resource: `jobs/${pricesheetId}/price-sheet-items`, urlParams: { popular: true } });

      return await response
        .json()
        .then((data: Product[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }
          const popularProducts: Product[] = [];

          data.forEach((product: Product) => {
            const nestedProducts = product.price_sheet_item_purchasables || [];
            const productId = nestedProducts.length ? nestedProducts[0].product_id : '';
            const category = productId ? nestedProducts[0].product.category : null;

            const productBase = {
              id: product.id,
              productId,
              name: product.name,
              full_name: product.full_name,
              description: product.description,
              download_all: product.download_all,
              price_sheet_item_type: product.price_sheet_item_type,
              has_digital_downloads: product.has_digital_downloads,

              featured_at: product.featured_at,
              display_priority: product.display_priority,

              price_cents: product.price_cents,
              image_url: product.image_url,
              digital_bundle: product.digital_bundle,
              digital_bundle_max_price_cents: product.digital_bundle_max_price_cents,
              digital_bundle_max_qty: product.digital_bundle_max_qty,
              digital_bundle_tiers: product.digital_bundle_tiers,
              digital_bundle_pricing_type: product.digital_bundle_pricing_type,
              ...(category ? { category } : {})
            };

            // Products
            if (product.price_sheet_item_type === 'product') {
              popularProducts.push(productBase);
            }
          });

          dispatch({
            type: SET_POPULAR_PRODUCTS,
            payload: {
              popularProducts: popularProducts
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { products: false } });
    }
  };

  const getProducts = async (payload: { pricesheetId: string }) => {
    const { pricesheetId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { products: true } });

      const response = await api.get({ resource: `jobs/${pricesheetId}/price-sheet-items` });

      return await response
        .json()
        .then((data: Product[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          // Hydrate state abstracting mapped data
          const products: Product[] = [];
          const featuredProducts: Product[] = [];

          const packages: Package[] = [];
          const categories: ProductCategory[] = [];

          data
            .sort((productA, productB) => {
              const nestedProductsA = productA.price_sheet_item_purchasables || [];
              const categoryA = nestedProductsA.length ? nestedProductsA[0].product.category : null;

              const nestedProductsB = productB.price_sheet_item_purchasables || [];
              const categoryB = nestedProductsB.length ? nestedProductsB[0].product.category : null;

              if (categoryA && categoryB) {
                if (categoryA.display_priority === categoryB.display_priority) {
                  // Sort products within categories by product priority

                  return productA.display_priority < productB.display_priority ? -1 : productA.display_priority > productB.display_priority ? 1 : 0;
                } else {
                  // Sort products into categories by category priority

                  return categoryA.display_priority < categoryB.display_priority ? -1 : 1;
                }
              }

              return 0;
            })
            .forEach((product: Product) => {
              const nestedProducts = product.price_sheet_item_purchasables || [];
              const productId = nestedProducts.length ? nestedProducts[0].product_id : '';
              const category = productId ? nestedProducts[0].product.category : null;

              const productBase = {
                id: product.id,
                productId,
                name: product.name,
                full_name: product.full_name,
                download_all: product.download_all,
                description: product.description,
                price_sheet_item_type: product.price_sheet_item_type,
                has_digital_downloads: product.has_digital_downloads,

                featured_at: product.featured_at,
                display_priority: product.display_priority,

                price_cents: product.price_cents,
                image_url: product.image_url,
                digital_bundle: product.digital_bundle,
                digital_bundle_max_price_cents: product.digital_bundle_max_price_cents,
                digital_bundle_max_qty: product.digital_bundle_max_qty,
                digital_bundle_tiers: product.digital_bundle_tiers,
                digital_bundle_pricing_type: product.digital_bundle_pricing_type,
                ...(category ? { category } : {})
              };

              // Products
              if (product.price_sheet_item_type === 'product') {
                products.push(productBase);
              }

              // Featured Products
              if (product.featured_at) {
                featuredProducts.push(productBase);
              }

              // Packages
              if (product.price_sheet_item_type === 'package') {
                packages.push({
                  id: product.id,
                  name: product.name,
                  full_name: product.full_name,
                  description: product.description,

                  display_priority: product.display_priority,

                  price_cents: product.price_cents,

                  products: nestedProducts.map((packageProduct) => ({
                    id: packageProduct.id,
                    product_id: packageProduct.product_id,

                    name: packageProduct.name,
                    full_name: packageProduct.full_name,
                    description: packageProduct.description,
                    has_digital_downloads: product.has_digital_downloads,

                    display_priority: packageProduct.display_priority,
                    digital_bundle: false,
                    digital_bundle_tiers: [],
                    digital_bundle_max_price_cents: null,

                    price_cents: packageProduct.product.price_cents,
                    image_url: packageProduct.product.image_url
                  }))
                });
              }

              // Categories
              if (product.price_sheet_item_type !== 'package' && nestedProducts.length) {
                const uniqueProductCategories = nestedProducts
                  .map((product) => product.product.category)
                  // Filter duplicated categories from product categories
                  .filter((category, index, array) => array.findIndex((item) => item.id === category.id) === index)
                  // Filter duplicated categories from state categories
                  .filter((category) => categories.some((item) => item.id === category.id) === false)
                  // Map data to what it supposed to be
                  .map((category) => ({
                    id: category.id,
                    name: category.name,
                    display_priority: category.display_priority,
                    thumbnail_url: category.thumbnail_url
                  }));

                categories.push(...uniqueProductCategories);
              }
            });

          dispatch({
            type: SET_PRODUCTS,
            payload: {
              pricesheetId,
              products: products.length ? products : null,
              featuredProducts: featuredProducts.length ? featuredProducts : null,
              popularProducts: null,
              packages: packages.length ? packages.sort((a, b) => a.display_priority - b.display_priority) : null,
              categories: categories.length ? categories.sort((a, b) => a.display_priority - b.display_priority) : null
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { products: false } });
    }
  };

  const createOrderItem = async (payload: CreateOrderItem) => {
    const { jobId, itemId, photoId, quantity, subjectAccessId, backgroundId, digitalBundleSize, complete = false } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: true } });

      const bodyPayload = {
        job_id: jobId,
        price_sheet_item_id: itemId,
        quantity,

        ...(photoId ? { photo_id: photoId } : {}),
        ...(subjectAccessId ? { access_id: subjectAccessId } : {}),
        ...(backgroundId ? { background_id: backgroundId } : {}),
        ...(digitalBundleSize ? { digital_bundle_size: digitalBundleSize } : {}),
        ...(typeof complete === 'boolean' ? { complete } : {})
      };

      const response = await api.post({ resource: 'order_items', bodyPayload });

      return await response
        .json()
        .then((data: OrderItem & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_ACTIVE_ITEM,
            payload: {
              activeItem: data,
              activeItemProduct: data.order_item_products[0]
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: false } });
    }
  };

  const updateOrderItem = async (payload: UpdateOrderItem) => {
    const { orderItemId, quantity, complete, disableAutoClear, digitalBundleSize } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: true } });

      const bodyPayload = {
        ...(quantity ? { quantity } : {}),
        ...(complete !== undefined ? { complete } : {}),
        ...(disableAutoClear ? { disable_auto_clear: true } : {}),
        ...(digitalBundleSize ? { digital_bundle_size: digitalBundleSize } : {})
      };

      const response = await api.put({ resource: `order_items/${orderItemId}`, bodyPayload });

      return await response
        .json()
        .then((data: OrderItem & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const { cart: currentCart, activeItem, activeItemProduct } = storefrontState;

          if (currentCart) {
            const { order_items: currentOrderItems } = currentCart;

            const updatedOrderItem = data;
            const updatedOrderItems = currentOrderItems.map((currentItem: OrderItem) =>
              currentItem.id === updatedOrderItem.id ? updatedOrderItem : currentItem
            );
            const updatedCart = { ...currentCart, order_items: updatedOrderItems };

            if (activeItem) {
              dispatch({
                type: SET_ACTIVE_ITEM,
                payload: { activeItem: updatedOrderItem, activeItemProduct }
              });
            }

            dispatch({
              type: SET_CART,
              payload: {
                cart: updatedCart
              }
            });
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: false } });
    }
  };

  const deleteOrderItem = async (payload: DeleteOrderItem) => {
    const { orderItemId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: true } });

      const response = await api.del({ resource: `order_items/${orderItemId}` });

      return await response
        .json()
        .then((data: ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const { cart } = storefrontState;

          const orderItems = cart.order_items.filter((item: OrderItem) => item.id !== orderItemId);
          const newCart = Object.assign({}, cart, { order_items: orderItems, order_items_count: orderItems.length });

          dispatch({
            type: SET_CART,
            payload: {
              cart: newCart
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: false } });
    }
  };

  const updateOrderItemProduct = async (payload: UpdateOrderItemProduct) => {
    const { orderItemProductId, orderItemProductNodeAttributes, quantity, layoutThemeId, previewConfirmed = undefined, includePreview = true } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { orderItemProduct: true } });

      const bodyPayload = {
        ...(quantity ? { quantity } : {}),
        ...(includePreview ? { include_preview: true } : {}),
        ...(layoutThemeId ? { layout_theme_id: layoutThemeId } : {}),
        ...(previewConfirmed !== undefined ? { preview_confirmed: previewConfirmed } : {}),
        ...(orderItemProductNodeAttributes ? { order_item_product_nodes_attributes: orderItemProductNodeAttributes } : {})
      };

      const response = await api.put({ resource: `order_item_products/${orderItemProductId}`, bodyPayload });

      return await response
        .json()
        .then((data: OrderItem & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          // update active item
          const newItem = JSON.parse(JSON.stringify(storefrontState.activeItem));
          const updateProductIndex = newItem.order_item_products.findIndex((product: OrderItemProduct) => product.id === orderItemProductId);

          newItem.order_item_products.splice(updateProductIndex, 1, data);

          // update cart orderItems with newItem
          const newCart = JSON.parse(JSON.stringify(storefrontState.cart));
          const updateCartProductIndex = newCart.order_items.findIndex((orderItem: OrderItem) => orderItem.id === newItem.id);

          if (updateCartProductIndex >= 0) {
            newCart.order_items.splice(updateCartProductIndex, 1, newItem);
          }

          dispatch({
            type: SET_ACTIVE_ITEM,
            payload: {
              activeItem: newItem,
              activeItemProduct: data
            }
          });

          dispatch({
            type: SET_CART,
            payload: {
              cart: newCart
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { orderItemProduct: false } });
    }
  };

  const deleteOrderItemProduct = async (payload: DeleteOrderItemProduct) => {
    const { orderItemId, orderItemProductId } = payload;
    try {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: true } });

      const response = await api.del({ resource: `order_item_products/${orderItemProductId}` });

      return await response
        .json()
        .then((data: ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const { cart } = storefrontState;

          const newOrderItems = cart.order_items.map((orderItem: OrderItem) => {
            if (orderItem.id === orderItemId) {
              return Object.assign({}, orderItem, {
                order_item_products: orderItem.order_item_products.filter((orderItemProduct) => orderItemProduct.id !== orderItemProductId)
              });
            } else {
              return orderItem;
            }
          });

          const newCart = Object.assign({}, cart, { order_items: newOrderItems, order_items_count: newOrderItems.length });

          dispatch({
            type: SET_CART,
            payload: {
              cart: newCart
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { orderItem: false } });
    }
  };

  const getRetouchPhotos = async (payload: { cartId: string }) => {
    const { cartId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { retouchPhotos: true } });

      const response = await api.get({ resource: `cart/${cartId}/retouch-configuration` });

      return await response
        .json()
        .then((data: RetouchPhoto[] & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_RETOUCH_PHOTOS,
            payload: { retouchPhotos: { [cartId]: data } }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { retouchPhotos: false } });
    }
  };

  const updateRetouchPhotos = async (payload: UpdateRetouchPhotos) => {
    const { cartId, photos } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { retouchPhotos: true } });

      const response = await api.post({ resource: `cart/${cartId}/configure-retouch`, bodyPayloadArray: photos });

      return await response
        .json()
        .then((data: RetouchPhoto[] & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_RETOUCH_PHOTOS,
            payload: { retouchPhotos: { [cartId]: data } }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { retouchPhotos: false } });
    }
  };

  const getThemes = async (payload: { productId: string }) => {
    const { productId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { themes: true } });

      const response = await api.get({ resource: `order_item_products/${productId}/layout-themes` });

      return await response
        .json()
        .then((data: LayoutTheme[] & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_THEMES,
            payload: {
              themes: data
            }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { themes: false } });
    }
  };

  const getPreview = async (payload: { layoutThemeId: string }) => {
    const { layoutThemeId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { preview: true } });

      const response = await api.get({ resource: `layout-themes/${layoutThemeId}/preview` });

      return await response
        .json()
        .then((data: Preview & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          const preview = { ...data, layoutThemeId };

          dispatch({
            type: SET_PREVIEWS,
            payload: {
              preview
            }
          });

          return preview;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { preview: false } });
    }
  };

  const createSuspiciousReport = async (payload: CreateSuspiciousReport) => {
    dispatch({ type: SET_REQUESTING, payload: { suspiciousReport: true } });

    try {
      const formData = { form_fields: payload, form_id: 5580234 };

      const response = await api.post({ resource: 'formstack/submit-form', bodyPayload: formData });

      return await response
        .json()
        .then((data: any & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { suspiciousReport: false } });
    }
  };

  const setCart = (payload: { cart: Cart }) => {
    const { cart } = payload;

    dispatch({ type: SET_CART, payload: { cart } });
  };

  const setActiveItem = (payload: { orderItem: OrderItem | null; product?: OrderItemProduct }) => {
    const { orderItem, product } = payload;

    const activeItemProduct = product || null;

    dispatch({
      type: SET_ACTIVE_ITEM,
      payload: { activeItem: orderItem, activeItemProduct }
    });

    return payload;
  };

  const setActiveProductNodesSnapshot = (payload: { productSnapshot: ActiveItemProductNodesSnapshot | null }) => {
    const { productSnapshot } = payload;

    dispatch({
      type: SET_ACTIVE_PRODUCT_NODES_SNAPSHOT,
      payload: { activeProductNodesSnapshot: productSnapshot }
    });

    return payload;
  };

  const setActivePhotoId = (payload: { photoId: string | null }) => {
    const { photoId } = payload;

    dispatch({
      type: SET_ACTIVE_PHOTO_ID,
      payload: { photoId }
    });

    return { photoId };
  };

  const resetCart = () => dispatch({ type: RESET_CART });

  const resetStorefrontState = () => dispatch({ type: RESET_STATE });
  const { invalidateFavoriteFacesPhotosQuery } = useGetFavoriteFacesPhotos({ storefrontGalleryId: storefrontState?.cart?.job_id });

  useEffect(() => {
    invalidateFavoriteFacesPhotosQuery();
  }, [storefrontState?.cart?.order_items_count]);

  const providerValue = useMemo(
    () => ({
      storefrontState,
      getCart,
      updateCart,
      getProducts,
      getPopularProducts,
      getThemes,
      getPreview,
      setCart,
      setActiveItem,
      setActiveProductNodesSnapshot,
      setActivePhotoId,
      createOrderItem,
      updateOrderItem,
      deleteOrderItem,
      updateOrderItemProduct,
      deleteOrderItemProduct,
      getRetouchPhotos,
      updateRetouchPhotos,
      createSuspiciousReport,
      resetCart,
      resetStorefrontState
    }),
    [storefrontState]
  );

  return <StorefrontContext.Provider value={providerValue}>{props.children}</StorefrontContext.Provider>;
};

export const useStorefrontContext = () => {
  const context = useContext(StorefrontContext);

  if (context === undefined) {
    throw new Error('useStorefrontContext must be used within a StorefrontProvider ');
  }

  return context;
};
