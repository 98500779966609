import { useReducer, createContext, useContext, useMemo } from 'react';

// Helpers
import * as api from 'utils/api';
import throwResponseError from 'utils/throwResponseError';

// Types
import { AdvancePayCredit, AdvancePayOption, ServerError, ErrorObject, Notification } from 'types';

interface AdvancePayState {
  advancePayOptions: AdvancePayOption[] | null;
  advancePayActiveCredit: AdvancePayCredit | null;

  requesting: { payment: boolean; credits: boolean };
  notification: Notification | null;
}

interface CreateAdvancePayPayment {
  jobId: string;
  advancePayCreditId: string;
  studioId?: string;
  count?: number;
  email?: string | null;
  phone?: string;
  source?: string;
  subjectId?: string;
  subjectDetails?: string;
}

interface AdvancePayProviderProps {
  advancePayState: AdvancePayState;

  createAdvancePayPayment: (payload: CreateAdvancePayPayment) => Promise<any>;
  getAdvancePayOptions: (payload: { giftCardSheetId: string }) => Promise<any>;
  setAdvancepayActiveCredit: (payload: { activeCredit: AdvancePayCredit }) => void;

  resetAdvancePayState: () => void;
}

// Constants
const SET_REQUESTING = 'SET_REQUESTING';
const SET_ERROR = 'SET_ERROR';

const SET_ADVANCEPAY_OPTIONS = 'SET_ADVANCEPAY_OPTIONS';
const SET_ADVANCEPAY_ACTIVE_CREDIT = 'SET_ADVANCEPAY_ACTIVE_CREDIT';

const RESET_STATE = 'RESET_STATE';

const initialState = {
  advancePayOptions: null,
  advancePayActiveCredit: null,

  requesting: { payment: false, credits: false },
  notification: null
};

export const AdvancePayContext = createContext<AdvancePayProviderProps>({
  advancePayState: initialState,

  createAdvancePayPayment: () => Promise.resolve(),
  getAdvancePayOptions: () => Promise.resolve(),
  setAdvancepayActiveCredit: () => null,
  resetAdvancePayState: () => null
});

const reducer = (state: AdvancePayState, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case SET_REQUESTING:
      return {
        ...state,
        requesting: { ...state.requesting, ...payload }
      };

    case SET_ERROR:
      return {
        ...state,
        notification: payload.notification
      };

    case SET_ADVANCEPAY_OPTIONS:
      return {
        ...state,
        advancePayOptions: payload.advancePayOptions,
        notification: payload.notification
      };

    case SET_ADVANCEPAY_ACTIVE_CREDIT:
      return {
        ...state,
        advancePayActiveCredit: payload.advancePayActiveCredit
      };

    case RESET_STATE:
      return {
        ...initialState
      };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
};

// Used as HOC Wrapper around Routes
export const AdvancePayProvider = (props: { children: React.ReactNode }) => {
  const [advancePayState, dispatch] = useReducer(reducer, initialState);

  // Actions
  const createAdvancePayPayment = async (payload: CreateAdvancePayPayment) => {
    const { studioId, jobId, email, phone, advancePayCreditId, source, subjectDetails, subjectId, count = 1 } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { payment: true } });

      const bodyPayload = {
        count,
        job_id: jobId,
        gift_card_option_id: advancePayCreditId,
        ...(studioId ? { studio_id: studioId } : {}),
        ...(email ? { email } : {}),
        ...(phone ? { phone } : {}),
        ...(source ? { source } : {}),
        ...(subjectId ? { subject_id: subjectId } : {}),
        ...(subjectDetails ? { subject_details: subjectDetails } : {})
      };

      const response = await api.post({ resource: `gift-card-options/${advancePayCreditId}/gift-cards/purchase`, bodyPayload });

      return await response
        .json()
        .then((data: any & ServerError) => {
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { payment: false } });
    }
  };

  const getAdvancePayOptions = async (payload: { giftCardSheetId: string }) => {
    const { giftCardSheetId } = payload;

    try {
      dispatch({ type: SET_REQUESTING, payload: { credits: true } });

      const response = await api.get({ resource: `gift-card-sheets/${giftCardSheetId}/gift-card-options`, urlParams: { page: 1, per_page: 100 } });

      return await response
        .json()
        .then((data: any & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          dispatch({
            type: SET_ADVANCEPAY_OPTIONS,
            payload: { advancePayOptions: data }
          });

          return data;
        })
        .catch((error: ErrorObject) => throwResponseError(response, error));
    } catch (error: any) {
      dispatch({ type: SET_ERROR, payload: { notification: { type: 'error', message: error.message, code: error.code } } });

      return Promise.reject(error.message);
    } finally {
      dispatch({ type: SET_REQUESTING, payload: { credits: false } });
    }
  };

  const setAdvancepayActiveCredit = (payload: { activeCredit: AdvancePayCredit }) => {
    const { activeCredit } = payload;

    dispatch({
      type: SET_ADVANCEPAY_ACTIVE_CREDIT,
      payload: { advancePayActiveCredit: activeCredit }
    });

    return { activeCredit };
  };

  const resetAdvancePayState = () => dispatch({ type: RESET_STATE });

  const providerValue = useMemo(
    () => ({
      advancePayState,
      createAdvancePayPayment,
      getAdvancePayOptions,
      setAdvancepayActiveCredit,
      resetAdvancePayState
    }),
    [advancePayState]
  );

  return <AdvancePayContext.Provider value={providerValue}>{props.children}</AdvancePayContext.Provider>;
};

export const useAdvancePayContext = () => {
  const context = useContext(AdvancePayContext);

  if (context === undefined) {
    throw new Error('useAdvancePayContext must be used within a AdvancePayProvider ');
  }

  return context;
};
