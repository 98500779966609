import { useRef, useEffect } from 'react';

// Plugins
import { animate } from 'motion';
import { AnimationOptionsWithOverrides } from '@motionone/dom';

// Helpers
import colors from '../../../../theme-colors';

// Styles
import 'components/Layout/PageLoader/PageLoader.css';

const GRADIENT_NUMBER: number = 20;

// Types
type BgStyle = 'white' | 'transparent' | 'semiTransparent';

function PageLoader({ bgStyle = 'white' }: { bgStyle?: BgStyle }) {
  const figureRef = useRef<HTMLElement>(null);

  const {
    pink: { DEFAULT: pinkDefault },
    primaryPurple: { 500: primaryPurpleDefault }
  } = colors;

  const gradientColors = `${primaryPurpleDefault}, ${pinkDefault}, `.repeat(GRADIENT_NUMBER).replace(/,\s*$/, '');

  useEffect(() => {
    if (figureRef.current) {
      const animationOptions: AnimationOptionsWithOverrides = { easing: 'linear', duration: GRADIENT_NUMBER * 0.8 };

      animate(figureRef.current, { backgroundPosition: '100% 100%' }, animationOptions);
    }
  }, []);

  return (
    <div className={`page-loader ${bgStyle === 'white' ? 'bg-white' : bgStyle === 'semiTransparent' ? 'bg-white bg-opacity-40' : ''}`}>
      <figure
        ref={figureRef}
        className="page-loader__oto"
        data-cy="page-loader"
        style={{ background: `linear-gradient(226deg, ${gradientColors})`, backgroundSize: `100% ${GRADIENT_NUMBER * 400}%` }}
      ></figure>
    </div>
  );
}

export default PageLoader;
