import { useEffect } from 'react';

// Providers
import { useGlobalContext } from 'providers';

// Helpers / Hooks
import * as api from 'utils/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import throwResponseError from 'utils/throwResponseError';

// Types
import { ErrorObject, ServerError, FacePhoto } from 'types';

export type GetFavoriteFacesParams = {
  storefrontGalleryId?: string;
  isEnabled?: boolean;
};

export const useGetFavoriteFacesPhotos = ({ storefrontGalleryId, isEnabled = false }: GetFavoriteFacesParams) => {
  const queryClient = useQueryClient();
  const { setGlobalNotification } = useGlobalContext();

  const queryKey = ['FavoriteFaces', storefrontGalleryId];
  const queryFn = async () => await getFavoriteFacesPhotos({ storefrontGalleryId });

  const invalidateFavoriteFacesPhotosQuery = () => queryClient.invalidateQueries({ queryKey });
  const getFavoriteFacesPhotos = async (payload: GetFavoriteFacesParams): Promise<FacePhoto[]> => {
    const { storefrontGalleryId } = payload;
    const resource = `jobs/${storefrontGalleryId}/photos?download-all`;

    try {
      const response = await api.get({ resource });

      if (!response.ok) {
        throwResponseError(response);
      }

      return await response
        .json()
        .then((data: FacePhoto[] & ServerError) => {
          // Catch error
          if (data.error) {
            const errorObject = { code: response.status, message: data.error_localized || data.error };

            throw errorObject;
          }

          return data;
        })
        .catch((error: ErrorObject) => {
          throwResponseError(response, error);
        });
    } catch (error: any) {
      return Promise.reject(error);
    }
  };

  const { isLoading, isFetching, isError, isFetched, data, error } = useQuery<FacePhoto[], Error>({
    queryKey,
    queryFn,
    staleTime: 30000,
    enabled: !!storefrontGalleryId && isEnabled
  });

  useEffect(() => {
    if (isError && !isLoading && !isFetching) {
      setGlobalNotification({ type: 'error', message: error?.message || 'Error fetching photo selections' });
    }
  }, [isError, error, isLoading, isFetching]);

  return { isLoading, isError, isFetched, isFetching, data, error, invalidateFavoriteFacesPhotosQuery };
};
